.bl-imageblock {
    @include breakpoint(large) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap:var(--layoutgridgap);

        &.layoutl {
            .ctrimg {
                grid-column:1 / span 10;
            }
        }
        &.layoutr {
            .ctrimg {
                grid-column:3 / span 10;
            }
        }
        &.layoutfull {
            .ctrimg {
                grid-column:1 / span 12;
            }
        }
    }

    img {
        @include w100bl;
    }
}
.bl-accordion {
    @include breakpoint(large) {
        @include grid12;
        .linner {
            grid-column:3 / span 8;
        }
    }

    .accitem {
        &:first-child {
            border-top:1px solid $lightbg;
        }
        border-bottom:1px solid $lightbg;

        .summary {
            display:flex;
            justify-content: space-between;
            align-items: center;

            cursor: pointer;

            margin-top:0.7em;
            margin-bottom:0.7em;

            h3 {
                color: $farbe2;
                font-weight: normal;
            }

            .title {
                margin-bottom: 0;
            }
            .icon {
                width: rem-calc(38);
                height: rem-calc(20);
                margin-left: rem-calc(10);
                display: block;
                transition: transform 0.15s;

                fill:$farbe2;
            }
        }
        .details {
            display:none;
            padding-right: rem-calc(60);
            margin-top: 1em;
            margin-bottom: 0.75em;

            p {
                margin-bottom: 0;
            }
            p + p {
                margin-top: 0.5em;
            }

            a {
                color:$farbe1;
            }
        }

        &.expanded {
            .icon {
                transform: rotate(180deg);
            }
        }
    }
}

//typo
.bl-accordion {

}
.bl-testimonials {
    text-align: center;
    .items {
        margin-top: 3em;

        @include breakpoint(large) {
            @include grid;
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint(medium down) {
            .item + .item {
                margin-top:3em;
            }
        }

        .personimg {
            @include w100bl;
            border-radius: 9999px;
            
            max-width: rem-calc(234);
            margin-left: auto;
            margin-right: auto;
        }
        .ctrtext {
            margin-top: 1em;

            max-width: rem-calc(410);
            margin-left: auto;
            margin-right: auto;
        }
        .ctrname {
            margin-top: 1.25em;
        }
    }
}

//typo
.bl-testimonials {
    .ctrtext {
        @include font-size-pt(26, 30);
        font-weight: 400;
        line-height: math.div(42, 30);
    }
    .ctrname {
        @include font-size-pt(17, 17);
        font-weight: 600;
        line-height: math.div(24, 17);
    }
}

.bl-iframeembed {
    iframe {
        @include w100bl;
        border: none;
    }

    @include breakpoint(medium) {
        &.size-d-16-9 iframe {
            aspect-ratio: 16 / 9;
        }

        &.size-d-1-1 iframe {
            aspect-ratio: 1 / 1;
        }

        &.size-d-80vh iframe {
            height: 80vh;
        }
    }

    @include breakpoint(small down) {
        &.size-m-16-9 iframe {
            aspect-ratio: 16 / 9;
        }

        &.size-m-1-1 iframe {
            aspect-ratio: 1 / 1;
        }

        &.size-m-80vh iframe {
            height: 80vh;
        }
    }
}
.bl-imageslider {
    @include breakpoint(large) {
        @include grid12;
        .ctrslider {
            grid-column:2 / span 10;
        }
    }

    --swiper-theme-color:#fff;
    --swiper-pagination-color:#fff;
    --swiper-pagination-bullet-inactive-color:#fff;
    //--swiper-pagination-bullet-inactive-opacity:1;
    //--swiper-pagination-bullet-size:19px;
    //@include rem-calc-sl(--swiper-pagination-bullet-horizontal-gap, 6, 0.4);
    //--swiper-pagination-bottom:16px;

    .swiper-pagination {
        //text-align: right;
        //padding-right: 16px;
        //box-sizing: border-box;
    }

    .swiper-slide {
        img {
            @include w100bl;
            //height:100vh;
            //height:100lvh;
            //object-fit: cover;
            //object-position: center center;
        }
    }
}


.bl-downloadlist {
    @include breakpoint(large) {
        @include grid12;

        .ctrdownloads {
            grid-column: 3 / span 8;
        }
    }

    ul {
        @include bulletlist;
    }
}

//typo
.bl-downloadlist {
    a {
        color:$farbe2;
    }
}

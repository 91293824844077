.bl-title {
    @include breakpoint(large) {
        @include grid12;

        &.layoutnormal .ctrtitle {
            grid-column: 3 / span 8;
        }
        &.layoutleft .ctrtitle {
            grid-column: 1 / span 8;
        }
    }

}
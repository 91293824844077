$black:#1f1d1b;
$white:#fff;
$beige: #f9f7ef;
$farbe1:#bda281;
$farbe2:#49493f;
$lightbg: #ece7de;
//$lightbg: #f3eedb;
$lightgray: #e8e8e8;

$bodybg: $beige;

$textcolor:#000;
$footertext:#fff;

$formerror: red;

/*
@mixin borderradius {
    border-radius: rem-calc(10);
}
*/

$buttonbg:$black;
$buttonbg-hover: #000;

:root {
    --layoutgridgap:2rem;

    @include rem-calc-sl(--lrminmargin, 120, math.div(20, 120));
}


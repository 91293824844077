.bl-aktuelllist {
    .items {
        @include breakpoint(large) {
            @include grid;
            grid-template-columns: repeat(2, 1fr);
        }    
        @include breakpoint(xlarge) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include breakpoint(medium down) {
        .item + .item {
            margin-top:rem-calc(30);
        }
    }

    .item {
        .ctrinfo {
            background-color: white;
            @include rem-calc-sl(padding, 40, math.div(30, 40));

            h2 {
                aspect-ratio: 290 / 140;
            }
        }
        .ctrimg img {
            @include w100bl;
        }
    }
}

//typo
.bl-aktuelllist {
    .ctrdate {
        @include font-size-pt(14, 14);
        line-height: math.div(20, 14);
        color:$farbe1;
    }
}

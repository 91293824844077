.bl-gallery {
    .ctrimages {
        @include grid;
        img {
            @include w100bl;
            //pthumb server side zoomcrop (&zc=1) is not supported, see: https://photoswipe.com/opening-or-closing-transition/#animating-from-cropped-thumbnail
            aspect-ratio: 4 / 3;
            object-fit: cover;
            object-position: center center; //see https://photoswipe.com/filters/#thumbbounds when using value other than "center center"
        }
    }

    &.imgperrow-2 {
        .ctrimages {
            grid-template-columns: repeat(1, 1fr);
            @include breakpoint(medium) {
                grid-template-columns: repeat(1, 1fr);
            }
            @include breakpoint(large) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
    &.imgperrow-3 {
        .ctrimages {
            grid-template-columns: repeat(1, 1fr);
            @include breakpoint(medium) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include breakpoint(large) {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    &.imgperrow-4 {
        .ctrimages {
            grid-template-columns: repeat(2, 1fr);
            @include breakpoint(medium) {
                grid-template-columns: repeat(3, 1fr);
            }
            @include breakpoint(large) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}
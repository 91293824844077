.bl-videoembed {
    @include breakpoint(large) {
        @include grid12;
        .ctrvideo {
            grid-column:3 / span 8;
        }
    }
    iframe {
        aspect-ratio: 16 / 9;
        @include w100bl;
        border:none;
    }
}

.content-video {
    width: 100%;
    aspect-ratio: 16 / 9;
}
#tarteaucitronRoot {
    --font-family: inherit;
    --border-radius: 0;
    --black: #3b3b3b;
    --link-color: #{$farbe2};
}

/*
// Button colors
//html body #tarteaucitronRoot button.tarteaucitronAllow.tarteaucitronAllow { //gleiche Specificity reicht nicht, deshalb naechste Zeile
html body #tarteaucitronRoot button.tarteaucitronAllow.tarteaucitronAllow {
    background-color: $farbe2 !important;
}
*/



#tarteaucitronRoot div#tarteaucitron {
    font-size: 0.8em;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    font-family: var(--font-family) !important;
    font-size: inherit !important;
}

.tarteaucitronCTAButton {
    font-family: var(--font-family) !important;
    padding: 9px 15px 8px 15px !important;
    margin-bottom: 10px !important;
    font-size: inherit !important;
    font-weight: 400;
    border-radius: var(--border-radius) !important;
}

.tarteaucitronLine .tarteaucitronDeny {
    background-color: var(--black) !important;
}

.tarteaucitronLine .tarteaucitronAllow,
.tarteaucitronLine .tarteaucitronDeny {
    opacity: 1 !important;
    padding: 9px 15px 8px 15px !important;
}

#tarteaucitronCloseAlert {
    font-family: var(--font-family) !important;
    padding: 9px 15px 8px 15px !important;
    margin-bottom: 10px !important;
    font-weight: 400;
    text-align: center !important;
    border-radius: var(--border-radius) !important;
    background-color: transparent !important;
    position: relative !important;
    max-width: min-content;
    margin-left: 20px !important;
}

#tarteaucitronDisclaimerAlert {
    display: block !important;
    margin-bottom: 1em !important;
    font-size: inherit !important;
    font-weight: 400;
    text-align: center !important;
}

.tarteaucitronAlertBigBottom {
    max-width: 100% !important;
    padding: 2em 1.5em 3em 1.5em !important;
    box-shadow: 0 -6px 10px rgba(0, 0, 0, 0.25);
}

@media all and (min-width: 900px) {
    .tarteaucitronAlertBigBottom {
        grid-template-columns: 50% repeat(3, 1fr) !important;
        align-items: start;
    }

    .tarteaucitronAlertBigBottom[style*="opacity: 1"] {
        display: grid !important;
    }

    #tarteaucitronDisclaimerAlert {
        text-align: left !important;
        max-width: 600px;
        justify-self: end;
    }
}

@media all and (min-width: 1300px) {
    .tarteaucitronAlertBigBottom {
        grid-template-columns: 50% repeat(3, 210px) !important;
    }
}

#tarteaucitron [aria-pressed="true"] {
    font-weight: normal !important;
}

.tarteaucitronCheck::before,
.tarteaucitronDeny::before,
.tarteaucitronCross::before,
.tarteaucitronPlus {
    display: none !important;
}

#tarteaucitronScrollbarAdjust {
    margin-bottom: 40px !important;
}

html body #tarteaucitronRoot button.tarteaucitronAllow {
    margin-left: 0;
}

#tarteaucitronInfo {
    border: none !important;
}

#tarteaucitronServices .tarteaucitronTitle button {
    font-weight: normal !important;
}

#tarteaucitronServices ul li::before {
    content: "";
    margin-left: 0;
    padding-right: 0;
}

#tarteaucitronBack {
    opacity: 0.95 !important;
}

#tarteaucitronAllAllowed,
#tarteaucitronAllDenied,
.tarteaucitronAllow,
.tarteaucitronDeny {
    font-family: var(--font-family) !important;
    border-radius: var(--border-radius) !important;
}

#tarteaucitronRoot div#tarteaucitron {
    overflow: hidden;
    position: relative;
}

div#tarteaucitronServices {
    box-sizing: border-box;
    position: unset;
    font-family: var(--font-family) !important;
    border-radius: var(--border-radius) !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2) !important;
    padding: 40px 40px 120px 40px !important;
    height: 600px !important;
    max-height: 80vh !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
    line-height: 1.6 !important;
}

@media all and (min-width: 768px) {
    div#tarteaucitronServices {
        padding: 40px 60px 120px 60px !important;
    }
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine,
div#tarteaucitronServices,
#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover,
#tarteaucitron .tarteaucitronH1,
#tarteaucitron #tarteaucitronInfo,
html body #tarteaucitronRoot div#tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    background-color: #ffffff !important;
    color: #3b3b3b !important;
}

#tarteaucitronRoot .tarteaucitronH1 {
    text-align: left !important;
    font-weight: bold;
}

#tarteaucitronMainLineOffset div#tarteaucitronInfo {
    text-align: left !important;
    max-width: 70% !important;
    padding: 0 !important;
    margin: 10px 0 30px 0 !important;
    font-weight: normal !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    float: none !important;
    margin-left: 0 !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
    display: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
    padding: 0 0 20px 0 !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button {
    font-weight: bold !important;
    background-color: #ffffff !important;
    color: var(--black) !important;
    font-size: inherit !important;
    padding: 0 !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
    background: #FFF !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
    width: 70% !important;
    margin-left: 0 !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    border-top: 2px solid var(--black) !important;
}

#tarteaucitronServices .tarteaucitronName a {
    font-size: inherit !important;
    color: var(--link-color) !important;
    display: block !important;
}

.tarteaucitronName span.tarteaucitronReadmoreSeparator {
    display: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover,
html body #tarteaucitronRoot #tarteaucitron .tarteaucitronHidden:hover ul li {
    background: #fff !important;
}

html body #tarteaucitronRoot #tarteaucitronSave button#tarteaucitronSaveButton {
    font-size: inherit !important;
}

#tarteaucitron #tarteaucitronClosePanel {
    font-family: var(--font-family) !important;
    color: var(--black) !important;
    background: #FFF !important;
}

html body #tarteaucitronRoot div#tarteaucitronSave {
    padding: 20px 40px !important;
    position: absolute !important;
    z-index: 99 !important;
    bottom: 0 !important;
    background: #FFF !important;
    left: 0 !important;
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.25);
    right: 0;
}

#tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronH3 {
    font-weight: inherit !important;
    font-size: inherit !important;
}

#tarteaucitronRoot #tarteaucitronServices .tarteaucitronInfoBox {
    display: block !important;
    position: relative !important;
    font-size: inherit !important;
    color: var(--black) !important;
    max-width: 100% !important;
    padding: 10px 0 !important;
    background: #fff !important;
    font-weight: normal !important;
    margin: 0 !important;
    width: 70% !important;
    z-index: 0 !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
    background: transparent !important;
}

#tarteaucitronCloseAlert::before {
    position: absolute;
    left: 0px;
    height: auto;
    width: 0.7em;
    margin-top: -0.1em;
}

button:not(#tarteaucitronBack):hover {
    filter: opacity(0.7) !important;
}

html body #tarteaucitronAlertBig #tarteaucitronCloseAlert {
    background: transparent !important;
}

#tarteaucitronAlertBig .tarteaucitronCTAButton.tarteaucitronDeny {
    display: none !important;
}
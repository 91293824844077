.wconstrain {
    margin-left: auto;
    margin-right: auto;

    max-width: calc(100% - var(--lrminmargin)*2);

    //Aktivieren, um max. Breite zu forcieren
    &.w_m {
        width: rem-calc(1800);
    }

    &.w_full {
        max-width: none;
    }
}

.bl-textwithimage {
    .cols {
        display: grid;
    }
    .colimage img {
        @include w100bl;
    }
    .coltext {
        height: fit-content;
        background: $white;
    }
    &.imgposl {
        .colimage {
            grid-column: 1 / span 11;
        }
        .coltext {
            margin-top:-3em;
            padding: 20px;
            grid-column: 2/span 11;
            z-index: 1;
        }
    }
    &.imgposr {
        .colimage {
            grid-column: 2 / span 11;
        }
        .coltext {
            margin-top:-3em;
            padding: 20px;
            grid-column: 1/span 11;
            z-index: 1;
        }
    }

    @include breakpoint(medium) {
        .cols {
            @include grid12;
            @include gridrow1;
        }
        &.imgposr {
            margin-bottom: 6em;
            .colimage {
                grid-column: 7 / span 5;
            }
            .coltext {
                grid-column: 1 / span 6;
                transform: translateX(10%);
                padding: 40px;
                margin-top: 2em;
            }
        }
        &.imgposl {
            margin-bottom: 6em;
            .colimage {
                grid-column: 1 / span 6;
            }
            .coltext {
                grid-column: 6 / span 6;
                transform: translateX(-10%);
                padding: 40px;
                margin-top: 2em;
            }
        }
    }
    
    @include breakpoint(large) {
        &.imgposr {
            .colimage {
                grid-column: 7 / span 5;
            }
            .coltext {
                grid-column: 2 / span 5;
                padding: 40px;
            }
        }
        &.imgposl {
            .colimage {
                grid-column: 2 / span 5;
            }
            .coltext {
                grid-column: 7/span 5;
            }
        }
    }


}

//typo
.bl-textwithimage {
    @include contenttable;
}
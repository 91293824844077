.bl-cards {
    .ctritems {
        @include grid;
        grid-template-columns: repeat(3, 1fr);
        @include breakpoint(small down) {
            grid-template-columns: 1fr;
        }
    }
    .ctritem {
        background-color: $white;
        .ctrovimg {
            img {
                @include w100bl;
                aspect-ratio: 16 / 9;
                object-fit: cover;
                object-position: center center;
            }
        }
        .ctrinfo {
            padding:1.5em;
        }

        .ctrovimg {
            overflow: hidden;
            img {
                transition: transform .2s;
            }
        }
        &:hover {
            .ctrovimg img {
                transform: scale(1.1);
            }
        }
    }
}

//typo
.bl-cards {
    h3 {
        color:$farbe1;
        margin-bottom: 0.5em;
    }
}

//Farbige Karten, falls Section weiss
section.bgdefault .bl-cards .ctritem {
    background-color: $lightbg;
}
html, body {
  overflow-x: clip;
  //overflow-x: hidden breaks sticky (z.B. Block "Timeline"/.bl-vorgehen)
  //https://www.terluinwebdesign.nl/en/css/position-sticky-not-working-try-overflow-clip-not-overflow-hidden/
}

body {
  margin:0;
  padding:0;

  background-color: $bodybg;
}


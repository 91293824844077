footer {
  background-color: $black;
  color: $beige;

  @include rem-calc-sl(padding-top, 100, 0.5);
  @include rem-calc-sl(padding-bottom, 100, 0.5);

  @include breakpoint(large) {
    .ctrmain {
      @include grid;
      grid-template-areas:
        "logoaddress social" 
        "logoaddress smalllinkscopy";
    }
    .ctrlogoaddress {
      grid-area: logoaddress;
    }
    .ctrsocial {
      grid-area: social;
      justify-self:end;
      align-self:start;
    }
    .ctrsmalllinkscopy {
      grid-area: smalllinkscopy;
      justify-self:end;
      align-self:end;
      text-align: right;
    }
  }
  @include breakpoint(medium down) {
    .ctrsocial, .ctrsmalllinkscopy {
      margin-top: 2em;
    }
  }

  .ctrlogoaddress {
    .ctrlogo img {
      display: block;
      @include rem-calc-sl(width, 200, 0.75); //Footer Logobreite
    }
    .ctrlogo + .address {
      margin-top: 2em;
    }
  }

  .ctrsocial {
    display: flex;
    gap:2em;

    .icon {
      height:rem-calc(30);
      display: block;
    }
  }

  .ctrsmalllinkscopy {
    .smalllinks {
      margin-top:1em;
      display: flex;
      gap:2em;
    }
    .copy {
      margin-top:1em;
    }
  }
}

//typo
footer {
  color:$footertext;
  font-size: 0.9em;
}

//part of library

ul.dropdownmenu {
    @include reset-list;
    ul {
        @include reset-list;
    }

    display: flex;
    gap:1.5em;

    li.level1.hassubmenu > ul { //Dropdown container
        display: none;
        &.visible {
            display:block;
        }
        /*
        transition:opacity 0.2s;
        &:not(.visible) {
            pointer-events: none;
            opacity: 0;
        }
        &.visible {
            opacity: 1;
        }
        */

        position: absolute;
        z-index:1;

        a {
            display: block;
        }
        .level3 a {
            padding-left:1.5em;
        }
    }
}

//part of library

.accordionmenu {
    ul {
        @include reset-list;
    }

    a {
        display:block;
    }
}


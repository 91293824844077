body {
  @include rem-calc-sl(--headerheight, 196, math.div(98, 196));
}

header {
  $logowidth:220;
  $paddingtop:40;
  $paddingbottom:$paddingtop;

  @include rem-calc-sl(padding-top, $paddingtop, 0.5);
  @include rem-calc-sl(padding-bottom, $paddingbottom, 0.5);
  .ctrlr {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  .ctrlogofs {
    img {
      display:block;
      @include rem-calc-sl(width, $logowidth, 0.6);
    }
  }

  //sticky
  position:sticky;
  top:0;
  z-index: 10;
  background-color: $beige;

  @include breakpoint(large) {
    //scroll down effect (make header smaller)
    transition: padding-top .2s, padding-bottom .2s;
    .ctrlogofs img {
      transition: width .2s;
    }
    &.small {
      @include rem-calc-sl(padding-top, $paddingtop*0.5, 0.5);
      @include rem-calc-sl(padding-bottom, $paddingbottom*0.5, 0.5);
        .ctrlogofs img {
        @include rem-calc-sl(width, $logowidth*0.6, 0.6);
      }
    }
  }
}

//.buttonbar {}

.button {
  display: block;
  width: fit-content;
  padding: 0.75em 2em;
  color: white;
  border-radius: 999px;
  background-color: $buttonbg;
  
  cursor: pointer;
}
button.button {
  //remove default <button> styling
  border:none;
  outline: none;
}

.button {
  transition: background-color 0.2s;

  &:hover {
    background-color: $buttonbg-hover;
  }
}

//typo
.button, .bl-richtext a.button {
  @include font-size-pt(17, 17);
  font-weight: 400;
  line-height: math.div(24, 17);
  color: $white;
  &:hover {
    background-color: $farbe1;
    color: $black;
  }
}

.book-btn {
  margin-top: 2.5em;
  &:hover {
    background-color: $farbe1;
    color: $black;
  }
}
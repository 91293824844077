@mixin grid {
    display:grid;
    gap:var(--layoutgridgap);
    & > * {
        min-width:0;
    }
}
@mixin grid12 {
    @include grid;
    grid-template-columns: repeat(12, 1fr);
}
@mixin gridrow1 {
    & > * {
        grid-row:1;
    }
}


@mixin flexitems {
    //CSS Variablen Parameter:
    //--itemsperrow - z.B. --itemsperrow: 4 - siehe _logolist.scss
    //--itemsgap - z.B. --itemsgap: var(--layoutgridgap)

    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--itemsgap);

    & > .item {
        min-width: 0;

        //Breite muss bei Flex explizit berechnet werden.
        //Es gibt keine Automatik ("Verfuegbaren Platz aufteilen") wie bei Grid, wenn flex-wrap aktiv.
        //Beispiel Berechnung von naechster Zeile:
        //width: calc(100% / 4 - 10px * (4-1) / 4);
        width: calc(100% / var(--itemsperrow) - var(--itemsgap) * (var(--itemsperrow) - 1) / var(--itemsperrow));
    }
}